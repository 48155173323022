<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >



    <base-material-card
   
      color="success"
      icon="mdi-clipboard-plus"
      v-if = "usertype == 'admin'"
      title="Add New Event"
      class="px-5 py-3"
    >
  
            <v-container class="py-0">
              <v-row>
          
          

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  outlined
                  dense
                    label="Event Name"
                    class="purple-input"
                    v-model="Finalitem.EventTitle"
                  />
                </v-col>

<v-col 
    cols="12"
    md="5">
    <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="Finalitem.date"
            label="Event Date"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="Finalitem.date"
          @input="menu2 = false"
        ></v-date-picker>
    </v-menu>
    </v-col>


                   <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  outlined
                  dense
                    label="Linked-in"
                    class="purple-input"
                    v-model="Finalitem.Linkedin"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  outlined
                  dense
                    label="Location"
                    class="purple-input"
                    v-model="Finalitem.location"
                  />
                </v-col>




            <v-col
                  cols="12"
                  md="12"
                >

            <v-textarea
              v-model="Finalitem.EventDesc"
              color="teal"
            >
              <template v-slot:label>
                <div>
                  Description 
                </div>
              </template>
            </v-textarea>

           </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <div>
            <div 
              v-for = "(imgs , index) in form.Images"
              :key="`t-${imgs}`"
              style="display: inline-block;">
              
              <v-icon  @click="ondelete(index)"> mdi-close</v-icon>
               <img 
                class="img" 
                :src="imgs"
                width="200" 
                height="200">
            </div>


          </div>
        
        <div class="control" style="text-align: left;">
           <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="onPickFile"
                >Add New Image
              <v-icon right dark>mdi-cloud-upload</v-icon>
             
               
              </v-btn>

              <input 
              type="file" 
              style="display: none" 
              ref="fileinput" 
              accept="image/*"
              @change="onfilepicked">
  </div>
              
                </v-col>

  <v-col
                  cols="12"
                  md="6"
                >

            <v-textarea
              v-model="form.Videos"
              color="teal"
            >
              <template v-slot:label>
                <div>
                  ** Youtube Videos
                </div>
              </template>
            </v-textarea>

           </v-col>


              </v-row>
              <template>
                <v-btn
                    color="success"
                    class="mr-0"
                    @click="InsertItemToDB"
                  >
                    Add New Event
                  </v-btn>
</template>
            </v-container>
                <div>
      <h5 style="color: red;">** Please Put Url Video in TextFeild Of Youtube Video And Split With New Line Between Videos</h5>
    </div>

    </base-material-card>

<base-material-card
icon="mdi-clipboard-text"
class="px-5 py-3"
>
<v-data-table
:headers="headers"
:items="Events"
sort-by="calories"
class="elevation-1"
>
<template v-slot:top>
  <v-toolbar
  flat
  >
  <v-toolbar-title>Events List</v-toolbar-title>
  <v-divider
  class="mx-4"
  inset
  vertical
  ></v-divider>
  <v-spacer></v-spacer>
  <v-dialog
  v-model="dialog"
  max-width="1000px"
  >
  <v-card>
    <v-card-title>
      <span class="text-h5">Edit</span>
    </v-card-title>

    <v-card-text>
      <v-container>
                 <v-container class="py-0">
              <v-row>
          
          

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  outlined
                  dense
                    label="Event Name"
                    class="purple-input"
                    v-model="editedItem.EventTitle"
                  />
                </v-col>

<v-col 
    cols="12"
    md="5">
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="editedItem.date"
            label="Event Date"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="editedItem.date"
          @input="menu = false"
        ></v-date-picker>
    </v-menu>
    </v-col>


                   <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  outlined
                  dense
                    label="Linked-in"
                    class="purple-input"
                    v-model="editedItem.Linkedin"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                  outlined
                  dense
                    label="Location"
                    class="purple-input"
                    v-model="editedItem.location"
                  />
                </v-col>




            <v-col
                  cols="12"
                  md="12"
                >

            <v-textarea
              v-model="editedItem.EventDesc"
              color="teal"
            >
              <template v-slot:label>
                <div>
                  Description 
                </div>
              </template>
            </v-textarea>

           </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <div>
            <div 
              v-for = "(imgs , index) in editedItem.Images"
              :key="`t-${imgs}`"
              style="display: inline-block;">
              
              <v-icon  @click="ondelete(index)"> mdi-close</v-icon>
               <img 
                class="img" 
                :src="imgs"
                width="200" 
                height="200">
            </div>


          </div>
        
        <div class="control" style="text-align: left;">
           <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="onPickFile"
                >Add New Image
              <v-icon right dark>mdi-cloud-upload</v-icon>
             
               
              </v-btn>

              <input 
              type="file" 
              style="display: none" 
              ref="fileinput" 
              accept="image/*"
              @change="onfilepicked">
  </div>
              
                </v-col>

  <v-col
                  cols="12"
                  md="6"
                >

            <v-textarea
              v-model="form.Videos"
              color="teal"
            >
              <template v-slot:label>
                <div>
                  ** Youtube Videos
                </div>
              </template>
            </v-textarea>

           </v-col>


              </v-row>
              <template>
                <v-btn
                    color="success"
                    class="mr-0"
                    @click="InsertItemToDB"
                  >
                    Add New Event
                  </v-btn>
</template>
            </v-container>
                <div>
      <h5 style="color: red;">** Please Put Url Video in TextFeild Of Youtube Video And Split With New Line Between Videos</h5>
    </div>

</v-container>
</v-card-text>

<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn
  color="blue darken-1"
  text
  @click="close"
  >
  Cancel
</v-btn>
<v-btn
color="blue darken-1"
text
@click="save"
>
Save
</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<v-dialog v-model="dialogDelete" max-width="1000px">
  <v-card>
    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-toolbar>
</template>
<template v-slot:item.actions="{ item }">
<!--   <v-icon
  small
  class="mr-2"
  @click="editItem(item)"
  >
  mdi-pencil
</v-icon> -->
<v-icon
small
@click="deleteItem(item)"
>
mdi-delete
</v-icon>
</template>
<template v-slot:no-data>
  <v-btn
  color="primary"
  @click="initialize"
  >
  Reset
</v-btn>
</template>

</v-data-table>
</base-material-card>

<base-material-card
icon="mdi-clipboard-text"
class="px-5 py-3"
>
<CCardBody>
    <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4">
            
          </v-text-field>
<v-data-table
:headers="JobRequestheaders"
:items="EventRequests"
:items-per-page="5"
sort-by="calories"
class="elevation-1"
:search="search"
>
<template v-slot:item.actions="{ item }">
  <v-icon
  small
  class="mr-2"
  @click="sendemail(item)"
  >
  mdi-check
</v-icon>
<!-- <v-icon
small
@click="deleteItem(item)"
>
mdi-delete
</v-icon> -->
</template>
</v-data-table>
  </CCardBody>
</base-material-card>


  </v-container>
</template>

<script>

  import { db ,fb } from '@/db'
  import { mapMutations, mapState } from 'vuex'
  import emailjs from 'emailjs-com';
  import QRCode from 'qrcode'
  import axios from 'axios'

  export default {
    data () {
      return {
        menu2: false,
        menu: false,
        ItemDetails : [],
        collapseDuration: 0,
        JobRequestheaders: [
        {
          text: 'Full Name',
          align: 'start',
          value: 'Fullname',
        },
        { text: 'Job Title', value: 'JobTitle' },
        { text: 'Email', value: 'Email' },
        { text: 'Phone', value: 'Phone' },
        { text: 'Company', value: 'Company' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        fields : [
          { key: 'ItemName', _style:'min-width:100px' },
          { key: 'Cost', _style:'min-width:100px;' },
          { key: 'SalePrice', _style:'min-width:100px;' },
          { key: 'type', _style:'min-width:100px;'},
          { 
            key: 'show_details', 
            label: '', 
            _style: 'width:1%', 
            sorter: false, 
            filter: false
          }
        ],
        selected: [],
        MainItem: [],
        components: [],

        form : {
            Videos : '',
            Images : [] ,
            snackbar : false,
            snakMsg : "Uploaded success"
        },
        Finalitem :{
          IsActive : true,
          Media :[],
          Linkedin : '',
          EventDesc : '',
          EventTitle : '',
          location : '',
          date : new Date().toISOString().substr(0, 10),
        },
        ImageFire: [],
        dialog: false,
        dialogDelete: false,
        search: '',
        desserts: [],
      editedIndex: -1,
      editedItem: {
        ItemName: '',
        ItemCost: 0,
        ItemUnit: 0,
        Is_Decoration: 0,
        AdditionalCost: 0,
      },
      defaultItem: {
        ItemName: '',
        ItemCost: 0,
        ItemUnit: 'g',
        ItemQTY : 0,
        Is_Decoration: 0,
        AdditionalCost: 0,
      },
      }
    },
    computed: {
      EventRequests(){
        return this.$store.state.EventRequests
      },
      Events(){
        return this.$store.state.Events
      },
      usertype(){
        return this.$store.state.userType
      },
      headers () {
        return [
          {
            text: 'Event Title ',
            align: 'start',
            value: 'EventTitle',
          },
          { text: 'Date', value: 'date',  align: 'center' },
          { text: 'Location', value: 'location' ,  align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      }
    },
   
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    methods: {
      sendemail(val){
        
        QRCode.toDataURL(val.Fullname + ';' + val.JobTitle + ';' + val.Company, function (err, url) {
            let data = 'data:image/png;base64,' + url.replace(/.*,/,'')

             ;
            fetch(data)
              .then(res => res.blob())
              .then(blob => {
                
                const file = new File([blob], "File name",{ type: "image/png" })
                var storageRef1 = fb.storage().ref('Events/QR/'+val.Fullname+'-'+val.Company)
                  let UploadTask1 = storageRef1.put(file);
                  UploadTask1.on('state_changed' , async function(snapshot){
                  }, function(error) {

                  },function() {
                  UploadTask1.snapshot.ref.getDownloadURL().then( function(downloadURL){
                    
                    val.Image = downloadURL;
                    emailjs.send('service_mo4wqv8', 'template_dq570t7', val, 'user_gKUe58eKzR9LXruQfrTrR')
                  .then((result) => {

                      alert('Email was sent')
                      
                      var data = JSON.stringify({
                        "userId": -1,
                        "firstName": val.Fullname,
                        "nickname": "",
                        "gender": "1",
                        "companyName": val.Company,
                        "mobileNumber": val.Phone,
                        "emailAddress": val.Email,
                        "jobTitle": val.JobTitle,
                        "Country": "Jordan",
                        "Password": "",
                        "UploadedProfilePicture": "",
                        "FirebaseToken": ""
                      });

                      var config = {
                        method: 'post',
                        url: 'http://20.203.101.85:9999/API/Summit/RegisterNewUser',
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data : data
                      };

                      axios(config)
                      .then(function (response) {
                        
                        console.log(JSON.stringify(response.data));
                      })
                      .catch(function (error) {
                        console.log(error);
                      });


                  }, (error) => {
                      console.log('FAILED...', error);
                  });

          })
              })})
            })
              return
            

     
      },
      SplitVideos(){
       let x = this.form.Videos.split("\n")
        
       for (let i = 0 ; i < x.length ; i++){
        this.Finalitem.Media.push({Type :'Video' , src : x[i] })
       }
       
    },
    toggleDetails (item , index) {
      this.$set(this.MainItem[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    getimage : async function(item , index) {
    await db.collection('Items')
        .doc('MainItem')
        .collection('Material')
        .doc(item.id)
        .get()
        .then(async doc => {
          let x =  await {Link : doc.data().Images[0]};
          document.getElementById(index).src = x.Link
           
        })
    },
  getitemDeatails : async function(item , index){
    let x = await db
      .collection('Items')
      .doc('MainItem')
      .collection('Material')
      .doc(item.id)
      .get()
      .then( snapshots => {
      return {...snapshots.data().components}
    })
    
    return x
  },
  InsertItemToDB(){
    this.SplitVideos()
    var context = this;
        db.collection('Events')
        .add( { ...this.Finalitem} )
        .then(docRef => {
          var Imagelist = new Array();
          var uuid = require("uuid");
          var x  = 0; var y = 0;
          
          for ( x = 0 ; x < context.form.Images.length ; x++){
          var storageRef = fb.storage().ref('EventPic/' + docRef.id +'/'+ context.uuidv4())
          let UploadTask = storageRef.put(context.ImageFire[x]);

          UploadTask.on('state_changed' , async function(snapshot){
          }, function(error) {

          },function() {
            
            UploadTask.snapshot.ref.getDownloadURL().then( function(downloadURL){
            context.Finalitem.Media.push({Type :'IMG' , src : downloadURL })
            y = y+1
            if (y == context.ImageFire.length)
              {
                
                return db.collection('Events')
                .doc(docRef.id)
                .update({"Media" : context.Finalitem.Media})
                .then(_ => {  
                  
                 context.Finalitem = ''
                 
                  })
              }
            })
          })
        }
        context.alert('Save Successfully')
    
        })
    },
    uuidv4() {
          return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    },
    rowClick(item, row)
      {
        var found = false;
        for(var i = 0; i < this.selected.length; i++) {
            if (this.selected[i].ItemName == item.ItemName) {
                found = true;
                break;
            }
          }
          if (!found){
            var newitem =  Object.assign({}, item);
            this.Finalitem.Cost =  this.Finalitem.Cost + parseFloat(newitem.ItemCost) + parseFloat(newitem.AdditionalCost);
              this.selected.push(newitem);
          }else{
             alert("Item Already Used");
          }
            
      },
      filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      },
      editItem (item) {
        this.editedIndex = this.selected.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.Events.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteMainItem (item , index) {
        var context = this; 
     if (confirm('Are you sure you want to delete this ?')) {
         db.collection('Items').doc("MainItem").collection("Material").doc(item.id)
          .delete().then(_=>{
            alert("Item Was Deleted ...")
             context.MainItem.splice(index);
          })
       
        }
      },
      deleteItemConfirm () {
        
         db.collection('Events').doc(this.Events[this.editedIndex].id)
          .delete()
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.selected[this.editedIndex], this.editedItem)
        } else {
          this.selected.push(this.editedItem)
        }
        this.close()

        this.Finalitem.Cost = 0;
         for(var i = 0; i < this.selected.length; i++) {
          this.Finalitem.Cost =  this.Finalitem.Cost + parseFloat(this.selected[i].ItemCost) + parseFloat(this.selected[i].AdditionalCost);
          }


      },

    onPickFile(){
      this.$refs.fileinput.click();
    },
    ondelete(index){
      this.form.Images.splice( index, 1 );

    },
    onfilepicked(event){
      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0){
        return alert('please add a valid file !')
      }
 
        const reader = new FileReader()
        reader.addEventListener('load' , () => {
           this.form.Images.push(reader.result)
        })
        reader.readAsDataURL(files[0])
        this.ImageFire.push(files[0])
    },
    getRowItem() {
      db
      .collection('Items')
      .doc('RowItem')
      .collection('Material')
      .get()
      .then(snapshots => {
      if(snapshots.docs.length === 0) {return []}
        const exchange = snapshots.docs.map(doc => ( {...doc.data()}))
        this.setdesserts(exchange)
        
      })
    },
    getMainItem(){ 
      db
      .collection('Items')
      .doc('MainItem')
      .collection('Material')
      .get()
      .then(snapshots => {
        if(snapshots.docs.length === 0) {return []}
        const exchange = snapshots.docs.map(doc => ({...doc.data(), id: doc.id , components : doc.data().components}))
        
        this.setMainItem(exchange)
        
      })
    },
    getTypeofItem(){
      db
      .collection('Categories')
      .get()
      .then(snapshots => {

        if(snapshots.docs.length === 0) {return []}
        const Types = snapshots.docs.map(doc => ({...doc.data(), id: doc.id , components : doc.data().components}))
        
        this.setTypeofItem(Types)
        
      })
    },
    getcategoryName : async function(CategoriesID){
        return await db.collection('Categories')
        .doc(CategoriesID)
        .get()
        .then(async snapshot => {
        

        let x = await snapshot.data().C_Name
        document.getElementById(CategoriesID).innerHTML = x


          return document.SupplierName;

        })
      },
    set_component(components){
      this.components = components
    },
    setdesserts(desserts){
      
      this.desserts = desserts
    },
    setMainItem(MainItem){
      this.MainItem = MainItem
    },
    setTypeofItem(Type){
      this.Type = Type
    },
    setDetailsItem(ItemDetails){
      
      this.ItemDetails = ItemDetails
    },
    },
   created(){
    this.$store.dispatch('bindEvents');
    this.$store.dispatch('bindeventRequests');
   }
  }
</script>


<style lang="scss">
  .tim-note {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    width: 260px;
  }
</style>
